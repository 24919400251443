@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&family=Montserrat:wght@700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


html,
body,
#root,
.app{
    height: 100%;
    height:100%;
    font-family: "DM Sans", sans-serif;
    @apply text-black
}

.no-scrollbar::-webkit-scrollbar{
    display: none;
}